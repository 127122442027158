<template>
 <div class="panel">
    <div class="main">
      <div v-if="selectedProject == null" class="d-flex justify-content-center mt-5">
        <b-spinner variant='primary' style='width: 3rem; height: 3rem;'></b-spinner>
      </div>
      <section v-else class='w-100 m-auto' :class="canEditProject ? '' : 'disabled'">
        <h4 class='px-5 mt-5'>Project Details</h4>
          <div class='px-5 text-secondary'>
            Review and update your project details.
          </div>
          <br>
          <b-container class="px-3">
          <FormulateForm @submit='updateProject()'>
            <b-row no-gutters class="no-top-margin-all-descendants">
            <b-col class="p-3">
              <FormulateInput
                  label='Name'
                  type='text'
                  label-class='form-label'
                  element-class='form-input'
                  id='projectName'
                  name='projectName'
                  validation='required'
                  error-behavior='submit'
                  :value='selectedProject.name'
                  v-model='projectName'/>
            </b-col>
            <b-col class="p-3">
              <FormulateInput
                  label='Reference ID'
                  type='text'
                  label-class='form-label'
                  element-class='form-input'
                  id='referenceId'
                  name='referenceId'
                  validation='optional'
                  error-behavior='submit'
                  :value='selectedProject.client_id || "" '
                  v-model='referenceId' />
            </b-col>
            </b-row>
            <b-row no-gutters class="no-top-margin-all-descendants">
              <b-col class="p-3">
                <FormulateInput
                  label='Address 1'
                  type='text'
                  label-class='form-label'
                  element-class='form-input'
                  id='projectAddress1'
                  name='projectAddress1'
                  validation='required'
                  error-behavior='submit'
                  :value='selectedProject.address_1'
                  v-model='projectAddress1' />
              </b-col>
              <b-col class="p-3">
                <FormulateInput
                  label='Address 2'
                  type='text'
                  label-class='form-label'
                  element-class='form-input'
                  id='projectAddress2'
                  name='projectAddress2'
                  validation='optional'
                  error-behavior='submit'
                  :value='selectedProject.address_2'
                  v-model='projectAddress2' />
              </b-col>
            </b-row>
            <b-row no-gutters class="no-top-margin-all-descendants">
              <b-col class="p-3">
                <FormulateInput
                  label='Latitude'
                  type='text'
                  label-class='form-label'
                  element-class='form-input'
                  min='-90'
                  max='90'
                  id='latitude'
                  name='latitude'
                  validation='required|number|min:-90|max:90'
                  error-behavior='submit'
                  :disabled='selectedProject.latitude'
                  :value='selectedProject.latitude'
                  :class="selectedProject.latitude ? 'disabled' : ''"
                  v-model='latitude' />
              </b-col>
              <b-col class="p-3">
                <FormulateInput
                  label='Longitude'
                  type='text'
                  label-class='form-label'
                  element-class='form-input'
                  min='-180'
                  max='180'
                  id='longitude'
                  name='longitude'
                  validation='required|number|min:-180|max:180'
                  error-behavior='submit'
                  :disabled='selectedProject.longitude'
                  :value='selectedProject.longitude'
                  :class="selectedProject.longitude ? 'disabled' : ''"
                  v-model='longitude' />
              </b-col>
            </b-row>
            <b-row no-gutters class="no-top-margin-all-descendants">
              <b-col class="p-3">
                <FormulateInput
                  label='Project Image'
                  label-class='form-label'
                  type='image'
                  id='projectImage'
                  name='projectImage'
                  help='Upload a PNG, JPG or JPEG image, file should be no larger than 10MB.'
                  v-model='projectImage'
                  validation="validateMaxFileSize|validateFileType"
                  :validation-rules="{ validateMaxFileSize, validateFileType }"
                  :validation-messages="{
                    validateMaxFileSize: `Max file size is: ${maxFileSize / 1048576}MB`,
                    validateFileType: 'Invalid file type'
                  }"
                  @file-upload-complete="updatingProject = false"
                  @file-removed="projectImage=null"/>                    
              </b-col>
            </b-row>
            <b-row no-gutters class="no-top-margin-all-descendants">
              <b-col class="pl-4 py-3">
              </b-col>
              <b-col class="pl-4 py-3" offset-md="5">
                <div class="button-row" v-if="canEditProject">
                  <FormulateInput class='pl-5' type="submit" :disabled="updatingProject">
                    <b-spinner v-if='updatingProject' class='mx-1' small>
                    </b-spinner>
                    {{updatingProject ? 'Updating' : 'Update'}}
                  </FormulateInput>
                </div>
              </b-col>
            </b-row>
          </FormulateForm>
        </b-container>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '@/network/eventbus';


export default {
  name: 'ProjectSettingsDetails',
  data() {
    return {
      projectName: '',
      referenceId: '',
      projectAddress1: '',
      projectAddress2: '',
      latitude: '',
      longitude: '',
      updatingProject: false,
      projectImage: '',
      maxFileSize: 10485760
    };
  },
  props: {
    canEditProject: {
      required: false
    },
  },
  async created() {
    //file file extension
    if (this.selectedProject) {
      let url = this.selectedProject.image;
      if (url) {
        let base_url = url.split('?')[0];
        let tokens = base_url.split('/');
        let fileName = tokens[tokens.length - 1];
        let path = tokens.slice(4, tokens.length).join('/');
          
        this.projectImage = [{url: this.selectedProject.image, name: fileName, file: fileName, path: path}];
      }
    }
  },
  methods: {    
    async updateProject() {
      this.updatingProject = true;
      const id = this.projectId;
      const updatedParameters = {
        name: this.projectName,
        client_id: this.referenceId,
        address_1: this.projectAddress1,
        address_2: this.projectAddress2,
        latitude: this.latitude,
        longitude: this.longitude,
        project_image: this.projectImage?.files ? this.projectImage.files[0]['path']: null
      };
      await this.$store.dispatch('project/updateProject', {id, updatedParameters});

      this.updatingProject = false;
      if (this.projectUpdatedError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to save new project details'
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'New project details saved'
        });
      }
    },
    validateFileType(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          let allowedTypes = ['png', 'jpg', 'jpeg'];
          let fileExtension = file.name.match(/\.(.+)$/);
          if (!allowedTypes.includes(fileExtension[1].toLowerCase())) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }
      return true;
    },
    validateMaxFileSize(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          if (file.file.size > this.maxFileSize) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }
      return true;
    },
    ...mapActions({
      getProjectAssetUploadUrl: 'project/getProjectAssetUploadUrl'
    })    
  },
  computed: {
    
    projectId() {
      return Number(this.$route.params.id);
    },
    getSubmitButtonLabel() {
      return this.updatingProject ? 'Updating Project...' : 'Update Project';
    },
    success() {
      return this.projectUpdated;
    },
    failure() {
      return this.projectUpdatedError;
    },
    ...mapGetters('project', ['selectedProject', 'projectUpdated', 'projectUpdatedError'])
  },
  watch: {
    projectId(newValue) {
      if (!newValue) {
        return;
      }
      this.getProjectAssetUploadUrl(newValue);
    },
  }
};
</script>

<style scoped>
*,
*>* {
  box-sizing: border-box !important;
}

body,
html {
  height: 100vh;
}

.ghost-button {
  font-size: 0.75em;
  text-align: right;
  font-weight: 700;
}


a.button {
  text-decoration: none;
}


.form-wrapper {
  flex-grow: 2;
}


.project-settings-form .button-row {
  justify-content: space-between;
}

.save-button {
  margin: 0;
}

.loading-spinner-container {
  margin-left: 50%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.panel {
  margin: 0;
  background-color: #fff;
  min-height: 20em;
  display: flex;
}

.main {
  padding: 1rem;
  width: 100%;
}


.form-label {
  width: 15%;
  margin-left: 2.5em;
  display: inline-block;
}

.form-input {
  width: 100%;
  display: inline-block;
  margin-top: 0rem;
}

.button-row {
  margin-left: 10em;
}
</style>

<style>

.loading-spinner {
  all: unset;
}

.authorization-message {
  padding-right: 50%;
  padding-top: 0;
  margin: 0;
}

.submit-button {
  all: unset;
  padding: 2em;
}

.disabled {
  pointer-events: none;
  opacity: .6;
}

.formulate-input[data-classification=button] button {
  background-color: #007bff;
  border: 0;
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=button] button:hover {
  background-color: #0262c9;
  border: 0;
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=button] button:active {
  background-color: #0262c9;
  border: 0;
  transition: all .2s ease-in-out;
}

.project-details {
  padding-left: 10%;
}

.dropdown-button {
  width: calc(100% - 3rem);
}
</style>